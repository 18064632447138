<template>
  
  <div class="wrap-template splash2-bg">
    <div class="wrap-home-3">
      <img src="/img/app/wedding.jpeg" style="width: 100%;">
    </div>
  </div>

  <div>
    <div class="splash2-buttom">
      <div class="splash2-buttom-text">
        <span>Discover</span> and Find Your Perfect <span>Life Partner</span>
      </div>
      <div class="splash2-tagline">
        <i>"Jingar Sangam app only for Jingar people."</i>
      </div>
      <div>
        <button class="splash-get-btn mt-5" @click="goToLoginPage">Let's Get Started</button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  methods: {
    goToLoginPage(){
      this.$router.push('/login');
    }
  }
};
</script>
