<template>
    <MenuBar />    
    
    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

     <section class="section-top-bar">
        <div class="listing-top-bar">
          <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
          <div class="top-status-bar" style="margin-right: 6%;">
            Membership
          </div>
        </div>
      </section>



    <section class="section-property">
      
      <div>
        <div class="point-box">
          <div class="title" :class="{'low':isLowPoint}">Membership View's</div>
          <div class="point" :class="{'low':isLowPoint}"><i class="fa fa-trophy"></i> {{currentCreditPoint}}</div>
        </div>
        <div class="point-box d-none">
          <div class="title">Membership</div>
          
          <div class="point-topup">
            
            <div class="point-topup-list" v-for="val in pointArr" :key="val.id" @click="getSelectPointTopup(val.current_price)">
              <input type="radio" name="topup-radio" :value="val.current_price"  ><span>{{val.membership}} ({{val.credit_point}}) view's</span>
              <span class="plan_price_div">
                <span class="current_price">&#8377;{{val.current_price}}/-</span>
              </span>
            </div>

            <div class="send-btn-2 topup-btn" @click="topupWallet()">
              Proceed to Topup
            </div>

          </div>
        </div>
        <div class="point-box" style="display: none;">
          <div class="history"><i class="fa fa-wallet"></i>Point Transection History</div>
        </div>
      </div>

    </section>

    <section style="margin-top: 25%;"></section>  
</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';
 
import axios from 'axios';

export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isSpinnerOpen:false,
      selectedPoint:false,
      selectedPointAmount:0,
      pointArr:null,
      currentCreditPoint:0,
      isUserName:'',
      isUserMobileNumber:'',
      senderMessageNumber:0,
      isLowPoint:false,
      isToasterOpen:false,
      isToasterError:'',
    }
  },
  methods:{
    getSelectPointTopup(amount){
      this.selectedPoint = true;
      this.selectedPointAmount = amount;
    },
    topupWallet(){
      if(this.selectedPoint == true && this.selectedPointAmount > 0){


        const message = "Name : "+this.isUserName+"\nMobile No. : "+this.isUserMobileNumber+"\nCredit : "+this.selectedPointAmount;
        const encodedMessage = encodeURIComponent(message);

        const waurl = "https://api.whatsapp.com/send?phone="+this.senderMessageNumber+"&text="+encodedMessage;
        window.open(waurl, '_blank');

      }else{
        this.showToaster('Please select one');
      }
    },
    async get_credit_point(){
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_credit_point.php',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            
            if(response.data.data){
              this.pointArr = response.data.data;
              this.currentCreditPoint = response.data.total_point;
              this.isUserName = response.data.user.name;
              this.isUserMobileNumber = response.data.user.mobile_number;
              this.senderMessageNumber= response.data.sender_no;
              if(response.data.total_point < 10){
                this.isLowPoint = true;
              }
            }else{
              this.pointArr = '';
            }
            this.isSpinnerOpen = false;
            
        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/index');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    gotoHome(){
      this.$router.push('/profileUser');
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
        this.isToasterError = '';
      }, 2000);
    }
  },
  mounted() {
    this.get_credit_point();
  }
};
</script>