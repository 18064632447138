<template>
  
  <MenuBar /> 

  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

  <div class="d-flex justify-content-center align-items-center toast-header wd-100">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

  <div v-if="showProfile">  
    <div class="swiper-container">
      <swiper :slides-per-view="1" :pagination="{ clickable: true }" :navigation="true" :loop="true">
        <swiper-slide v-for="(slide, index) in rec.more_img" :key="index">
          <img :src="slide.img_url" :alt="'Slide ' + (index + 1)" class="d-block w-100"/>
        </swiper-slide>
        <!-- Add pagination -->
        <div class="swiper-pagination"></div>
        <!-- Add navigation -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>

        <div class="view-top-bar">
          <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
          <div class="save-icon"><i class="fa fa-eye"></i> {{rec.total_view}}</div>
        </div>

      </swiper>
    </div>

    <section class="section-profile">

      <div class="title">{{rec.name}} <span class="profile-bookmark" :class="{'hide':profile_save}" @click="userSaveMemeber"><i class="fa fa-bookmark iconbold" :class="{'show':isBookmark}"></i></span></div>
      <div class="profile-work">{{rec.current_work}}</div>
      
      <div class="info-header">
        <div class="profile-note" :class="{'show':profile_msg_show}"><i class="fa fa-info"></i>{{profile_msg}}</div>
        <div class="title">Personal Info.</div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-1">
                <div class="view-lable">Gender</div>
                <div class="view-lable-text">{{rec.gender}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Gotra</div>
                <div class="view-lable-text">{{rec.gotra}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">Dob</div>
                <div class="view-lable-text">{{rec.dob}}</div>
              </td>
              <td>
                <div class="view-lable">Birth Time</div>
                <div class="view-lable-text">{{rec.dob_time}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">Birth Place</div>
                <div class="view-lable-text">{{rec.dob_place}}</div>
              </td>
              <td>
                <div class="view-lable">Height</div>
                <div class="view-lable-text">{{rec.height}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">Manglik</div>
                <div class="view-lable-text">{{rec.manglik}}</div>
              </td>
              <td>
                <div class="view-lable">Status</div>
                <div class="view-lable-text">{{rec.current_status}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-2" colspan="2">
                <div class="view-lable">Hoodies</div>
                <div class="view-lable-text">{{rec.other_details}}</div>
              </td>
            </tr>
          </table>
        </div>

        <div class="title">Education Info.</div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-1">
                <div class="view-lable">Education</div>
                <div class="view-lable-text">{{rec.education}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Details</div>
                <div class="view-lable-text">{{rec.education_text}}</div>
              </td>
            </tr>
          </table>
        </div>

        <div class="title">Working</div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-1">
                <div class="view-lable">Current Work</div>
                <div class="view-lable-text">{{rec.current_work}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Salary</div>
                <div class="view-lable-text">{{rec.salary}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-2" colspan="2">
                <div class="view-lable">Details</div>
                <div class="view-lable-text">{{rec.work_text}}</div>
              </td>
            </tr>
          </table>
        </div>

        <div class="title">Family Info.</div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-1">
                <div class="view-lable">Father</div>
                <div class="view-lable-text">{{rec.father_name}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Occupation</div>
                <div class="view-lable-text">{{rec.father_work}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">Mother</div>
                <div class="view-lable-text">{{rec.mother_name}} ({{rec.mother_gotra}})</div>
              </td>
              <td>
                <div class="view-lable">Occupation</div>
                <div class="view-lable-text">{{rec.mother_work}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">Brother</div>
                <div class="view-lable-text">{{rec.brother}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Sister</div>
                <div class="view-lable-text">{{rec.sister}}</div>
              </td>
            </tr>
          </table>
          
        </div>

        <div class="title">Location </div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-2" colspan="2">
                <div class="view-lable">Address</div>
                <div class="view-lable-text">{{rec.address}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">City</div>
                <div class="view-lable-text">{{rec.city}}</div>
              </td>
              <td class="td-1">
                <div class="view-lable">District</div>
                <div class="view-lable-text">{{rec.district}}</div>
              </td>
            </tr>
            <tr>
              <td class="td-1">
                <div class="view-lable">State</div>
                <div class="view-lable-text">{{rec.state}}</div>
              </td>
              <td class="td-1">
                <div class="view-lable">Country</div>
                <div class="view-lable-text">India</div>
              </td>
            </tr>
          </table>
        </div>
        
        <div class="title">Contact No. </div>
        <div class="info-talbe">
          <table class="table">
            <tr>
              <td class="td-1">
                <div class="view-lable">Mobile No.1</div>
                <div class="view-lable-text">{{rec.mobile_1}}</div>
              </td>
              <td class="td-11">
                <div class="view-lable">Mobile No.2</div>
                <div class="view-lable-text">{{rec.mobile_2}}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </section>

    <section class="section-property">
      <div class="section-header">
        <div class="title">Recommended</div>
        <div class="sea_all"></div>
      </div>
      <!--  New Matches profile -->
      <div class="pull-container">
        <div class="pull-scroller">
          <div class="pull-swap">
            
            <div class="pull-swap-card" v-for="val in more_data" :key="val.id"   @click="goToProfilePageMore(val.id)"  >
              <div class="pull-swap-card-img">
                <img :src="val.img" >
              </div>
              <div class="pull-swap-card-body">
                <div class="title">{{val.name}}</div>
                <div class="category">{{val.work}} | {{val.age}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>

    <section style="margin-top: 25%;"></section>   
  </div>
  
  <div v-else>
    <div class="">
      <img src="/img/app/no-profile.jpg" style="width: 100%;">
      <div class="profile-no-found"><u>{{isMessage}}</u></div>
    </div>
  </div>

  

</template>

<script>

import MenuBar from './layout/particle/MenuBar.vue';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import axios from 'axios';

export default {
  components: {
     MenuBar,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slides: [
        {
          image: '/img/property/2.png'
        },
        {
          image: '/img/property/3.png'
        },
        {
          image: '/img/property/4.png'
        }
      ],
      rec:[],
      more_data:[],
      showProfile:false,
      isMessage:'',
      profile_view:false,
      profile_save:false,
      profile_ping:false,
      member_id:0,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      isURLPath:0,
      isBookmark:false,
      profile_msg_show:false,
      isNumberShow1:false,
      isNumberShow2:false,
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods:{
    gotoHome(){
      this.$router.push('/home');
    },
    goToProfilePageMore(id) {
      this.$router.push({ name: 'profilemore', params: { id: id }});
    },
    async get_profile() {
    
      this.isSpinnerOpen = true;
      //alert(this.member_id);

      try {
        const response = await axios.get('/api/profile.php?profile_id='+this.member_id,{
           headers: {
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            this.rec = response.data.data;
            this.isBookmark = response.data.data.save_true;
            this.profile_view = response.data.data.profile_view;
            this.profile_save = response.data.data.profile_save;
            this.profile_ping = response.data.data.profile_ping;
            this.isNumberShow1 = response.data.data.isNumberShow1;
            this.isNumberShow2 = response.data.data.isNumberShow2;

            this.profile_msg = response.data.data.profile_msg;
            this.profile_msg_show = this.profile_view;

            this.more_data = response.data.more_data;
            this.isSpinnerOpen = false;
            this.showProfile = true;

        }else if(response.data.status == 400){

            this.showToaster(response.data.massage);
            this.$router.push('/index');

        }else{

          this.showToaster(response.data.massage);
          this.showProfile = false;
          this.isSpinnerOpen = false;

        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showProfile = false;
        this.showToaster(error);
      }
    },
    async userSaveMemeber() {
    
        this.isSpinnerOpen = true;

        const formData = new FormData();
        formData.append('id', this.member_id);
        formData.append('type', 3);

        this.isToasterOpen = true;

        try {
          const response = await axios.post('/api/memberviewsave.php',formData,{
             headers: {
              'Authorization': 'Bearer YOUR_TOKEN',
              'device_token': localStorage.getItem('device_token')
            }
          });
          
          if(response.data.status == 200){
            
              this.isSpinnerOpen = false;
              this.showToaster(response.data.massage);
            
          }else if(response.data.status == 400){

            this.showToaster(response.data.massage);
            this.$router.push('/index');

          }else{
            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);
          }

        } catch (error) {
          
          this.isSpinnerOpen = false;
          this.showToaster(error);
        }

    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      this.isMessage = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
        this.isToasterError = '';
      }, 2000);
    },
    send_massage(mobile_number){
      if(mobile_number != '' && mobile_number != null){

        const message = "Hello, I am "+localStorage.getItem('user_name')+" form Jingar Sangam app";

        const encodedMessage = encodeURIComponent(message);

        const waurl = "https://api.whatsapp.com/send?phone="+mobile_number+"&text="+encodedMessage;
        window.open(waurl, '_blank');

      }else{
        this.showToaster('Something error!');
      }
    },
  },
  mounted() {
    const username = localStorage.getItem('user_name');
    if(username == '' || username == 'null' ){
      this.$router.push('/profileUpdate');
    }else{
      this.member_id = this.$route.params.id;
      this.scrollToTop();
      this.get_profile();
      //this.setUserHistory(3);
    }
  }
};
</script>

<style scoped>
.swiper-container{
  height: 10%;
}
.swiper{
    height: 450px;
}
.w-100{
  height: 100%;
}
.view-top-bar{
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 8%;
    width: 84%;
}
.view-top-bar .back-icon{
  padding: 8px 12px;
    background: white;
    width: fit-content;
    border-radius: 100px;
    float: left;
}
.save-icon{
  padding: 8px 12px;
    background: white;
    width: fit-content;
    border-radius: 100px;
    float: right;
}
.swiper-button-next{
  display: none !important;
}
.swiper-button-prev{
 display: none !important;
}
</style>
