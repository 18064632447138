<template>
<nav class="custome-nav">
    <div class="men-new " :class="{ 'activem': isActiveHome}" @click="goToMenuPage('home')"><i class="fa fa-home"></i>Home</div>
    <div class="men-new" :class="{ 'activem': isActiveExplore}" @click="goToSearchPage('all','1')"><i class="fa fa-map-marker"></i>Explore</div>
    <div class="men-new" :class="{ 'activem': isActiveAdd}" @click="goToMenuPage('memberAdd')"><i class="fa  fa-plus-circle"></i>ADD</div>
    <div class="men-new" :class="{ 'activem': isActiveSave}" @click="goToMenuPage('saveMember')"><i class="fa fa-heart"></i>Favorite</div>
    <div class="men-new" :class="{ 'activem': isActiveProfile}" @click="goToMenuPage('profileUser')"><i class="fa fa-user"></i>Profile</div>
</nav>

</template>

<script>
export default {
  data(){
    return{
      isActiveHome:false,
      isActiveExplore:false,
      isActiveAdd:false,
      isActiveSave:false,
      isActiveProfile:false,
    }
  },
  methods: {
    goToMenuPage(menupage) {
      this.$router.push('/'+menupage);
    },
    goToSearchPage(slug,id) {
      this.$router.push({ name: 'listing', params: { slug : slug, id: id }});
    }
  },
  mounted() {
    //console.log(this.$route.href);
    
    const href =  this.$route.href;
    //alert(href);
    if(href == '/home'){
      this.isActiveHome = true;
      this.isActiveExplore = false;
      this.isActiveAdd = false;
      this.isActiveSave = false;
      this.isActiveProfile = false;
    }
    if(href.search("listing") == 1 || href.search("profile/") == 1){
      this.isActiveHome = false;
      this.isActiveExplore = true;
      this.isActiveAdd = false;
      this.isActiveSave = false;
      this.isActiveProfile = false;
    }
    if(href == '/memberAdd'){
      this.isActiveHome = false;
      this.isActiveExplore = false;
      this.isActiveAdd = true;
      this.isActiveSave = false;
      this.isActiveProfile = false;
    }
    if(href == '/saveMember'){
      this.isActiveHome = false;
      this.isActiveExplore = false;
      this.isActiveAdd = false;
      this.isActiveSave = true;
      this.isActiveProfile = false;
    }
    if(href == '/profileUser' || href == '/profileUpdate' || href == '/memberList' || href == '/privacyPolicy' || href == '/helpCenter' || href == '/memberviewlist' || href == '/creditPlan'){
      this.isActiveHome = false;
      this.isActiveExplore = false;
      this.isActiveAdd = false;
      this.isActiveSave = false;
      this.isActiveProfile = true;
    }
  }
};
</script>