<template>
  
  <MenuBar /> 
  
  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>

  <section class="section-top-bar">
    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Profile List
      </div>
    </div>
  </section>


  <section class="section-property listing-result-bar">
    <div class="section-header3">
      <div class="title">({{total_rec}}) Profile List</div>
      <div class="add-new" @click="addNewMember"><i class="fa fa-plus"></i></div>
    </div>

    <div v-if="rec != '' ">
      <div class="pull-container pull-container2">
        
        <div class="m-menu " :class="{ 'show': isShowMenuPopup }">
          <ul>
            <li><i class="fa fa-pencil"></i> Edit</li>
            <li><i class="fa fa-eye"></i> View</li>
            <li><i class="fa fa-bookmark"></i> Save</li>
            <li class="border-none"><i class="fa fa-trash"></i> Inactive</li>
            <li class="border-none"></li>

            <li class="border-top text-center " @click="closeMenuPopup"><i class="fa fa-close"></i></li>
          </ul>
        </div>

        <div class="swap-card-2" v-for="val in rec" :key="val.id"  @click="goToProfilePage(val.id)"  >
          <div class="swap-card-img-2">
            <img :src="val.img" >
          </div>
          <div class="swap-card-2-body">
            <div class="title">{{val.name}} <span class="float-r d-none" @click="showMenuPopup"><i class="fa fa-ellipsis-v"></i></span> </div>
            <div class="category">Profile ID : <span class="profile-id"><u>#{{val.member_id}}</u></span> </div>
            <div class="category">Who ? : <span><i class="fa fa-user"></i></span> {{val.profile_who}}</div>
            <div class="category">Status : <span v-html="val.status"></span></div>
            <div class="swap-card-3-counter">
            <div class="vister">
              <i class="fa fa-sort-amount-desc"></i>
             : {{val.total_view}}</div>
            <div class="float-r f-15" @click="userSaveMemeber(val.id)">
              <i class="fa fa-bookmark iconbold show" ></i>{{val.total_save}}
            </div>
          </div>

          </div>
        </div>
        
        

      </div>
    </div>

    <div v-else>
      <div class="text-center">
        <div class="fetch-data">{{isFinddata}}</div>
      </div>
    </div>

  </section>

  <section style="margin-top: 25%;"></section>  
</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';

import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      rec:'',
      total_rec:0,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      isShowMenuPopup:false,
      isFinddata:'Fetch Your Added Profile',
    };
  },
  methods: {
    showMenuPopup(){
      this.isShowMenuPopup =true;
    },
    closeMenuPopup(){
      this.isShowMenuPopup = false;
    },
    addNewMember() {
      this.$router.push('/memberAdd');
    },
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    async get_member_listing() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/memberlisting.php',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            if(response.data.data){
              this.rec = response.data.data;
              this.total_rec = response.data.total_rec;
            }else{
              this.rec = '';
              this.isFinddata = 'No Profile Added!';
              this.total_rec=0;
            }
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/index');

        }else{
          this.isSpinnerOpen = false;
          //this.showToaster(response.data.massage);
          this.rec = '';
          this.isFinddata = 'No Profile Added!';
          this.total_rec=0;
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    gotoHome(){
      this.$router.push('/profileUser');
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
        this.isToasterError = '';
      }, 2000);
    }
  },
  mounted() {
    this.get_member_listing();
  }
};
</script>