<template>
  <MenuBar />
  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">
        Privacy Policy 
      </div>
    </div>
  </section>

  <section class="section-property listing-result-bar">
    <div class="p-3">
      <ul>
        <li>जीनगर संगम ऐप का उपयोग केवल जीनगर समाज के लोग ही कर सकते हैं।</li>
        <l>उपयोगकर्ता के मोबाइल नंबर को विभिन्न स्रोतों के माध्यम से सत्यापित किया जाएगा।
        इसके बाद कोई ही यूजर ऐप का इस्तेमाल कर सकता है।</l>

        <li>आपके द्वारा जोड़ी गई कोई भी प्रोफ़ाइल पहले सत्यापित करने के बाद ही ऐप पर लाइव की जाएगी।</li>

        <li>आप अपनी इच्छानुसार जो भी प्रोफ़ाइल जोड़ते हैं, उसकी सारी जानकारी अन्य उपयोगकर्ता ऐप पर देख सकते हैं।
        ऐसी अनुमति आप देते है </li>

        <li>किसी भी प्रोफ़ाइल को देखने के लिए, आपके पास ऐप सदस्यता दृश्य होना चाहिए। इसे इस्तेमाल करने के बाद ही कोई भी यूजर किसी भी प्रोफाइल की सारी जानकारी देख सकता है।</li>
      </ul>
    </div>
  </section>

  <section style="margin-top: 25%;"></section>  
  
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';
export default {
  components: {
    MenuBar
  },
  methods:{
    gotoHome(){
      this.$router.push('/profileUser');
    }
  }
};
</script>