<template>
  <MenuBar />
    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">
        Help Center 
      </div>
    </div>
  </section>

  <section class="section-property listing-result-bar">

    <div class="help-bar">
      <div><u><b>Any Question</b></u></div>
      <div class="pt-5"><i>Send Massage On Whatsapp</i></div>
      <i class="fab fa-whatsapp"></i>
      <div @click="openWhatsapp"><u><i>Click Now</i></u></div>
    </div>
  </section>

  <section style="margin-top: 25%;"></section>  
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';
import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      sendWaNumber:0,
      sendWaMsg:'',
    }
  },
  methods:{
    gotoHome(){
      this.$router.push('/profileUser');
    },
    async get_wa_number() {
      
      this.isSpinnerOpen = true;

      try {
        
        const response = await axios.get('/api/get_app_data.php?type=help-me');

        if(response.data.status == 200){
          
          this.sendWaNumber = response.data.data.helpme_whatsapp_number;
          this.sendWaMsg = response.data.data.helpme_whatsapp_massage;
          this.isSpinnerOpen = false;
        }else{
          localStorage.setItem('device_token','');
          localStorage.setItem('user_name','');
          this.isSpinnerOpen = false;
        }
      } catch (error) {
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    openWhatsapp(){
      if(this.sendWaNumber != 0 && this.sendWaMsg != ''){

        const message = this.sendWaMsg;
        const encodedMessage = encodeURIComponent(message);

        const waurl = "https://api.whatsapp.com/send?phone="+this.sendWaNumber+"&text="+encodedMessage;
        window.open(waurl, '_blank');

      }else{
        this.showToaster('Something error!');
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    },
  },
  mounted() {
    this.get_wa_number();
  }
};
</script>