<template>

  <MenuBar />

	<div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>
  
  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>

  <div class="msg-popup" :class="{'show':isShowMsg}" >
    <div> {{isShowUserMsg}}
      <div class="term-read-btn" @click="closeUserMsg">Close</div>
    </div>
  </div>

    
  <section class="section-top-bar pt-1">
    <div class="section-top-location">
      <div class="topbar-user-name"><i class="fa fa-user"></i> Hi, {{topUserName}}</div>
    </div>
    <div class="section-top-bell d-none" :class="{'low':isLowPoint}">
      <i class="fa fa-trophy"></i> {{isCreditPoint}}
    </div>
  </section>

  <section class="section-top-search">
    <div class="section-top-search-input">
      <input type="number" v-model="searchProfileID" placeholder="Search by Profile ID">
    </div>
    <div class="section-top-search-btn" @click="searchById">
      <i class="fa fa-search"></i>
    </div>
  </section>

  <section class="section-category">
    <div class="section-header p-0">
      <div class="title">Search by Profession</div>
      <div class="sea_all"></div>
    </div>
    <div class="category-bar">
      <div class="section-category-box" v-for="val in work_cart.data" :class="{'last':((val.orderno%4)==0)}" :key="val.id"  @click="goToSearchPage('working',val.id)">
        <div class="category-icon">
          <i :class="val.icon"></i>
        </div>
        <div class="title-2">{{val.slug}}</div>
      </div>
    </div>
  </section>
  
  <section class="section-property">
    <div class="section-header">
      <div class="title">{{swap_cart.title}}</div>
      <div class="sea_all d-none" @click="goToSearchPage('time','latest')">{{swap_cart.see_all}}</div>
    </div>
    <!--  New Matches profile -->
    <div class="pull-container">
      <div class="pull-scroller">
        <div class="pull-swap">
          <div class="pull-swap-card" v-for="val in swap_cart.data" :key="val.id"   @click="goToProfilePage(val.id)"  >
            <div class="pull-swap-card-img">
              <img :src="val.img" >
            </div>
            <div class="pull-swap-card-body card-view" :class="{'show':val.member_view}" >
              <div class="title">{{val.name}}</div>
              <div class="category">{{val.work}} | {{val.age}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_1.title}}</div>
      <div class="sea_all d-none" @click="goToSearchPage('working',cart_1.see_all_link)">{{cart_1.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2"  v-for="val in cart_1.data" :key="val.id" >
        <div class="swap-card-img-2"  @click="goToProfilePage(val.id)" >
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body card-view" :class="{'show':val.member_view}" >
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">{{val.age}} | {{val.gotra}}</div>
          <div class="category">{{val.address}}</div>
          <div class="swap-card-2-counter">
            <div class="vister"></div>
            <div class="float-r" :class="{'hide':val.save_true}" @click="userSaveMemeber(val.id)">
              <i class="fa fa-bookmark iconbold" :class="{'show':val.member_save}" ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_2.title}}</div>
      <div class="sea_all d-none"  @click="goToSearchPage('working',cart_2.see_all_link)">{{cart_2.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2" v-for="val in cart_2.data" :key="val.id" >
        <div class="swap-card-img-2"  @click="goToProfilePage(val.id)">
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body card-view" :class="{'show':val.member_view}" >
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">{{val.age}} | {{val.gotra}}</div>
          <div class="category">{{val.address}}</div>
          <div class="swap-card-2-counter">
            <div class="vister"></div>
            <div class="float-r" :class="{'hide':val.save_true}" @click="userSaveMemeber(val.id)">
              <i class="fa fa-bookmark iconbold" :class="{'show':val.member_save}" ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_3.title}}</div>
      <div class="sea_all d-none"  @click="goToSearchPage('manglik',cart_3.see_all_link)">{{cart_3.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2" v-for="val in cart_3.data" :key="val.id" >
        <div class="swap-card-img-2" @click="goToProfilePage(val.id)" >
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body card-view" :class="{'show':val.member_view}" >
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">{{val.age}} | {{val.gotra}}</div>
          <div class="category">{{val.address}}</div>
          <div class="swap-card-2-counter">
            <div class="vister"></div>
            <div class="float-r" :class="{'hide':val.save_true}" @click="userSaveMemeber(val.id)">
              <i class="fa fa-bookmark iconbold" :class="{'show':val.member_save}" ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section style="margin-top: 25%;"></section>    
  
</template>


<script>

import MenuBar from './layout/particle/MenuBar.vue';

import axios from 'axios';

export default {
  components: {
    MenuBar
  },
  data() {
    return {
      rec:null,
      swap_cart:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      work_cart:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_1:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_2:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_3:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_3_counter:0,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      isCreditPoint:0,
      isLowPoint:false,
      topUserName:'Guest',
      searchProfileID:'',
      isShowMsg:false,
      isShowUserMsg:'',
      isCloseShowMsg:false,
    };
  },
  methods: {
    showUserMsg(){
      const f = localStorage.getItem('read_user_msg');
      if(f == '' || f == null){
        this.isShowMsg = true;
      }
    },
    closeUserMsg(){
      this.isShowMsg = false;
      this.isCloseShowMsg = true;
      localStorage.setItem('read_user_msg',1);
    },
    goToSearchPage(slug,id) {
      this.$router.push({ name: 'listing', params: { slug : slug, id: id }});
    },
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    async get_home_page_listing() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/home.php',{
           headers: {
            'device_token': localStorage.getItem('device_token')
          }
        });
                
        if(response.data.status == 200){
          
            this.rec = response.data.data;
            this.isCreditPoint = response.data.total_point;
            this.swap_cart.title = response.data.data.swap_cart.title;
            this.swap_cart.see_all = response.data.data.swap_cart.see_all;
            this.swap_cart.see_all_link = response.data.data.swap_cart.see_all_link;
            this.swap_cart.data = response.data.data.swap_cart.data;

            this.work_cart.title = response.data.data.work_cart.title;
            this.work_cart.see_all = response.data.data.work_cart.see_all;
            this.work_cart.see_all_link = response.data.data.work_cart.see_all_link;
            this.work_cart.data = response.data.data.work_cart.data;

            this.cart_1.title = response.data.data.cart_1.title;
            this.cart_1.see_all = response.data.data.cart_1.see_all;
            this.cart_1.see_all_link = response.data.data.cart_1.see_all_link;
            this.cart_1.data = response.data.data.cart_1.data;

            this.cart_2.title = response.data.data.cart_2.title;
            this.cart_2.see_all = response.data.data.cart_2.see_all;
            this.cart_2.see_all_link = response.data.data.cart_2.see_all_link;
            this.cart_2.data = response.data.data.cart_2.data;

            this.cart_3.title = response.data.data.cart_3.title;
            this.cart_3.see_all = response.data.data.cart_3.see_all;
            this.cart_3.see_all_link = response.data.data.cart_3.see_all_link;
            this.cart_3.data = response.data.data.cart_3.data;
            
            if(response.data.user_msg !=''){
              this.isShowUserMsg = response.data.user_msg;
              this.showUserMsg();
            }

            this.isSpinnerOpen = false;

            if(response.data.total_point < 10){
              this.isLowPoint = true;
            }

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/index');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
      
    },
    async userSaveMemeber(member_id) {
    
        this.isSpinnerOpen = true;

        const formData = new FormData();
        formData.append('id', member_id);
        formData.append('type', 3);

        try {
          const response = await axios.post('/api/memberviewsave.php',formData,{
             headers: {
              'Authorization': 'Bearer YOUR_TOKEN',
              'device_token': localStorage.getItem('device_token')
            }
          });
          
          if(response.data.status == 200){
              this.isCreditPoint = response.data.total_point;
              if(response.data.total_point < 10){
                this.isLowPoint = true;
              }
              this.isSpinnerOpen = false;
              this.showToaster(response.data.massage);
            
          }else if(response.data.status == 400){

            this.showToaster(response.data.massage);
            this.$router.push('/index');

          }else{
            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);
          }

        } catch (error) {
          
          this.isSpinnerOpen = false;
          this.showToaster(error);
        }

    },
    searchById(){
      var id = 'mid'+this.searchProfileID;
      this.$router.push({ name: 'profile', params: { id:  id}});
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
        this.isToasterError = '';
      }, 2000);
    }
  },
  mounted() {
    const username = localStorage.getItem('user_name');
    if(username == '' || username == 'null' || username == 'undefined' ){
      this.$router.push('/profileUpdate');
    }else{
      const un = localStorage.getItem('user_name')
      if(un != 'undefined'){
        this.topUserName = un;
      }
      this.get_home_page_listing();
    }
  }
};
</script>