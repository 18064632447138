<template>
  <MenuBar />

  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>

  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">
        View
      </div>
    </div>
  </section>

  <section class="section-property listing-result-bar">
    <div class="section-header3">
      <div class="title">({{total_rec}}) View Profile</div>
    </div>

  <div v-if="rec != '' ">
    <div class="pull-container pull-container2">
          
      <div class="swap-card-2" v-for="val in rec" :key="val.id"   >
        <div class="swap-card-img-2">
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body">
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">{{val.age}} | {{val.gotra}}</div>
          <div class="category">{{val.address}}</div>
          <div class="swap-card-2-counter">
            <div class="vister">
              <i class="fa fa-sort-amount-desc"></i>
             : {{val.total_view}}</div>
            <div class="float-r"></div>
          </div>
        </div>
      </div>

      
    </div>
  </div>

    <div v-else>
      <div class="text-center">
        <div class="fetch-data">{{isFinddata}}</div>
      </div>
    </div>
  </section>

  <section style="margin-top: 25%;"></section>  
  
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';
import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      topUserName:'',
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      total_rec:0,
      isFinddata:'Fetch Your View Profile',
      rec:[]
    };
  },
  methods:{
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    gotoHome(){
      this.$router.push('/profileUser');
    },
    async get_save_list() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/getUserSaveView.php?type=1',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            
            if(response.data.data){
              this.rec = response.data.data;
              this.total_rec= response.data.total_rec;
            }else{
              this.rec = '';
              this.isFinddata = 'No View Profile!';
              this.total_rec=0;
            }
            
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/index');

        }else{
          this.isSpinnerOpen = false;
          //this.showToaster(response.data.massage);
          this.rec = '';
          this.isFinddata = 'No View Profile!';
          this.total_rec=0;
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
        this.isToasterError = '';
      }, 2000);
    }
  },
  mounted() {
    this.topUserName = localStorage.getItem('user_name');
    this.get_save_list();
  }
};
</script>