import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router';


/* ------------------------------------------------------------------*/
import SplashPage from './home/layout/SplashPage.vue';
import SplashPage2 from './home/layout/SplashPage2.vue';
import LoginPage from './home/layout/LoginPage.vue';
import loginPasscode from './home/layout/LoginVerify.vue';
 
// ./home Folder
import homePage from './home/HomePage.vue';
import listing from './home/ListingPage.vue';
import profile from './home/ProfileView.vue';
import profilemore from './home/ProfileViewMore.vue';

// Profile Folder
import profileUser from './home/profile/ProfilePage.vue';
import profileUpdate from './home/profile/ProfileUpdate.vue';

// Member Folder
import memberList from './home/member/MemberList.vue';
import memberAdd from './home/member/MemberAdd.vue';

// Pages Folder
import memberViewList from './home/Pages/ViewMember.vue';
import saveMember from './home/Pages/SaveMember.vue';
import creditPlan from './home/Pages/CreditPlan.vue';
import privacyPolicy from './home/Pages/PrivacyPolicy.vue';
import helpCenter from './home/Pages/HelpCenter.vue';

   
const router = createRouter({
  history: createWebHistory(),
  routes: [
    
    /* --------------------- */
    { path: '/index', component: SplashPage },
    { path: '/welcome', component: SplashPage2 },
    { path: '/login', component: LoginPage },
    { path: '/loginPasscode/:mob',name: 'loginPasscode',component: loginPasscode },

    { path: '/', component: SplashPage },
    { path: '/home', component: homePage },
    { path: '/listing/:slug/:id',name: 'listing',component: listing },
    { path: '/profile/:id',name: 'profile',component: profile },
    { path: '/profilemore/:id',name: 'profilemore',component:profilemore },

    { path: '/profileUser', component: profileUser },
    { path: '/profileUpdate', component: profileUpdate },

    { path: '/memberList', component: memberList },
    { path: '/memberAdd', component: memberAdd },
    
    { path: '/memberViewList', component: memberViewList },
    { path: '/saveMember', component: saveMember },
    { path: '/creditPlan', component: creditPlan },
    { path: '/privacyPolicy', component: privacyPolicy },
    { path: '/helpCenter', component: helpCenter },

    /* --------------------- */
 
  ]
});


const app = createApp(App);
app.use(router);
export default router;

app.mount('#app');
