<template>
  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

    <div class="wrap-template">
      <div class="login-otp">
        <img src="/img/app/otp.jpg" style="width: 85%;">
      </div>  
    </div>

  <div class="wrap-template">
        
      <div class="login-otp-div">
        <div class="otp-title">OTP Verification</div>
        <div class="login-opt-text-2">Enter the OTP send to <span>+91 <span>{{mobileNumber}}</span></span></div>

        <div class="passcode-code-bar">
          <div class="passcode-code">
            <input type="number" min="0" max="9" v-model="passcode_1" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()" required="">
            <input type="number" min="0" max="9" v-model="passcode_2" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()">
            <input type="number" min="0" max="9" v-model="passcode_3" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()">
            <input type="number" min="0" max="9" v-model="passcode_4">
          </div>

          <div class="login-opt-text-3">Dont receive the OTP ? <span @click="getLoginCode">RESEND OTP</span> </div>

          <div class="pt-0">
            <div class="login-term-text"><span class="term-text" @click="openTerm"> <u>Terms & Conditions</u></span>
            </div>
          </div>
        </div>
 
        <div class="send-btn-2 login-btn-2"  style="margin-top: 2%"  @click="getLoginWithPassCode()"><i class="fa fa-send"></i> Verify</div>
      </div>

      <div class="login-term" :class="{'show':TermCondition}" >
        <div> 
          <ul>
            <li>जीनगर संगम ऐप का उपयोग केवल जीनगर समाज के लोग ही कर सकते हैं।</li>
            <l>उपयोगकर्ता के मोबाइल नंबर को विभिन्न स्रोतों के माध्यम से सत्यापित किया जाएगा।
            इसके बाद कोई ही यूजर ऐप का इस्तेमाल कर सकता है।</l>

            <li>आपके द्वारा जोड़ी गई कोई भी प्रोफ़ाइल पहले सत्यापित करने के बाद ही ऐप पर लाइव की जाएगी।</li>

            <li>आप अपनी इच्छानुसार जो भी प्रोफ़ाइल जोड़ते हैं, उसकी सारी जानकारी अन्य उपयोगकर्ता ऐप पर देख सकते हैं।
            ऐसी अनुमति आप देते है </li>

            <li>किसी भी प्रोफ़ाइल को देखने के लिए, आपके पास ऐप सदस्यता दृश्य होना चाहिए। इसे इस्तेमाल करने के बाद ही कोई भी यूजर किसी भी प्रोफाइल की सारी जानकारी देख सकता है।</li>
          </ul>
          <div class="term-read-btn" @click="closeTerm">Read</div>
        </div>
      </div>

    
  </div>
  
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      mobileNumber:'',
      TermCondition:false,
      isReadTermCondition:false,
      passcode:'',
      passcode_1:'',
      passcode_2:'',
      passcode_3:'',
      passcode_4:'',
      sendWaNumber:0,
      sendWaMsg:'',
    };
  },
  methods: {
    openTerm(){
      this.TermCondition = true;
    },
    closeTerm(){
      this.TermCondition = false;
      this.isReadTermCondition = true;
    },
    sendpasscode(){
      //alert(this.sendWaNumber);
      if(this.sendWaNumber != ''){
        
        this.passcode_1 = ''; 
        this.passcode_2 = '';
        this.passcode_3 = ''; 
        this.passcode_4 = ''; 

        localStorage.setItem('login_otp',1);
        var mn = this.sendWaNumber;
        window.open('https://wa.me/'+mn+'?text='+this.sendWaMsg, '_blank');
        

      }
    },
    async getLoginCode() {
      if(this.mobileNumber != ''){

        const phoneRegex = /^[0-9]{10}$/; 
      
        if (phoneRegex.test(this.mobileNumber)) {
          
          this.isSpinnerOpen = true;
          
          try {
            this.get_wa_number();
            const response = await axios.get('/api/myLogin.php?mobile_number='+this.mobileNumber);
            
            this.isSpinnerOpen = false;

            if(response.data.status == 200){
            
              this.sendpasscode();
              
            }else{
            
              this.showToaster(response.data.massage);
              
            }
          } catch (error) {

            this.isSpinnerOpen = false;
            this.showToaster(error);
          }

        }else{
          this.showToaster('Enter valid mobile number!.');
        }
      }else{
        this.showToaster('Please enter mobile number!.');
      }
    },
    async getLoginWithPassCode() {
      
        this.passcode = this.passcode_1 +''+ this.passcode_2 +''+ this.passcode_3 +''+ this.passcode_4;
        
        if (this.mobileNumber == '') {
          this.showToaster('Mobile number not found!.');
          return false;
        }

        const phoneRegex = /^[0-9]{10}$/; 
        
        if (!phoneRegex.test(this.mobileNumber)) {
          this.showToaster('Please check mobile number!.');
          return false;
        }

        if (this.passcode >= 1000 && this.passcode <= 9999) {
          
          if(this.isReadTermCondition == true){
            this.isSpinnerOpen = true;
          
            try {

              const response = await axios.get('/api/verify_otp.php?mobile_number='+this.mobileNumber+'&otp='+this.passcode);
              
              if(response.data.status == 200){
                
                localStorage.setItem('device_token',response.data.data.device_token);
                localStorage.setItem('read_user_msg',1);
                localStorage.setItem('login_otp',1);

                if(response.data.data.name != '' && response.data.data.name != null){

                  localStorage.setItem('user_name',response.data.data.name);

                  this.$router.push('/home');

                }else{

                  this.$router.push('/profileUpdate');

                }

              }else{

                this.isSpinnerOpen = false;
                this.showToaster(response.data.massage);

              }
            } catch (error) {
              
              this.isSpinnerOpen = false;
              
              this.showToaster(error);
            }
          }else{
            this.showToaster('Please read term & consition');
          }

        }else{
          this.showToaster('Please enter OTP');
        }

    },
    async get_wa_number() {
      
      this.isSpinnerOpen = true;

      try {
        
        const response = await axios.get('/api/get_app_data.php?type=login');

        if(response.data.status == 200){
          
          this.sendWaNumber = response.data.data.whatsapp_number;
          this.sendWaMsg = response.data.data.whatsapp_massage;

          this.isSpinnerOpen = false;
          this.sendpasscode();
        }else{

          localStorage.setItem('device_token',0);
          localStorage.setItem('user_name','');
          localStorage.setItem('read_user_msg',0);
          localStorage.setItem('login_otp',0);

          this.isSpinnerOpen = false;
          this.showToaster('Network issue');
          setTimeout(() => {
            this.$router.push('/welcome');
          }, 2500);

        }
      } catch (error) {

        localStorage.setItem('device_token',0);
        localStorage.setItem('user_name','');
        localStorage.setItem('read_user_msg',0);
        localStorage.setItem('login_otp',0);

        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
   this.mobileNumber = this.$route.params.mob;
   const otp_status = localStorage.getItem('login_otp');
   if(otp_status == 0){
      //this.get_wa_number();
   }
  }
};
</script>