<template>

  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

    <div class="wrap-template">
      <div class="login-top">
        <img src="/img/app/splash.jpeg" style="width: 75%;">
      </div>  
    </div>

  <div class="wrap-template">
        
      <div class="login-input-div" style="width: 100%">
        <div class="otp-title name-title">JINGAR SANGAM</div>

        <div class="send-btn-2 login-btn btn-shadow"  style="margin-top: 35%"  @click="goToLoginPage()"><i class="fa fa-send-o"></i> Get Started </div>

      </div>

    
  </div>
  
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      mobileNumber:'',
      sendWaNumber:0,
      sendWaMsg:'',
    };
  },
  methods: {
    async getLoginCode() {
      if(this.mobileNumber != ''){

        const phoneRegex = /^[0-9]{10}$/; 
      
        if (phoneRegex.test(this.mobileNumber)) {
          
          this.isSpinnerOpen = true;
          
          try {
            const response = await axios.get('/api/myLogin.php?mobile_number='+this.mobileNumber);
            
            if(response.data.status == 200){

              const mob = this.mobileNumber;
              this.$router.push({ name: 'loginPasscode', params: { mob: mob }});

            }else{
              this.isSpinnerOpen = false;
              this.showToaster(response.data.massage);
            }
          } catch (error) {

            this.isSpinnerOpen = false;
            this.showToaster(error);
          }

        }else{
          this.showToaster('Enter valid mobile number!.');
        }
      }else{
        this.showToaster('Please enter mobile number!.');
      }
    },
    async check_token_on_server() {

      var tokan_number  = localStorage.getItem('device_token');

      if(tokan_number != 0 && tokan_number != null){

        this.isSpinnerOpen = true;

        try {
          const response = await axios.get('/api/verifytoken.php',{
             headers: {
              'device_token': tokan_number
            }
          });
          
          if(response.data.status == 200){
            
            localStorage.setItem('user_name',response.data.user_name);
            localStorage.setItem('read_user_msg',1);
            localStorage.setItem('login_otp',1);

            this.isSpinnerOpen = false;
            this.$router.push('/home');

          }else{

            localStorage.setItem('device_token',0);
            localStorage.setItem('user_name','');
            localStorage.setItem('read_user_msg',0);
            localStorage.setItem('login_otp',0);

            this.isSpinnerOpen = false;
            this.get_wa_number();

          }
        } catch (error) {
          
          localStorage.setItem('device_token',0);
          localStorage.setItem('user_name','');
          localStorage.setItem('read_user_msg',0);
          localStorage.setItem('login_otp',0);

          this.isSpinnerOpen = false;
          this.get_wa_number();
        }

      }else{
        localStorage.setItem('device_token',0);
        localStorage.setItem('user_name','');
        localStorage.setItem('read_user_msg',0);
        localStorage.setItem('login_otp',0);
        this.get_wa_number();
      }
    },
    async get_wa_number() {
      
      this.isSpinnerOpen = true;

      try {
        
        const response = await axios.get('/api/get_app_data.php?type=login');

        if(response.data.status == 200){
          
          this.sendWaNumber = response.data.data.whatsapp_number;
          this.sendWaMsg = response.data.data.whatsapp_massage;

          this.isSpinnerOpen = false;

        }else{

          localStorage.setItem('device_token',0);
          localStorage.setItem('user_name','');
          localStorage.setItem('read_user_msg',0);
          localStorage.setItem('login_otp',0);

          this.isSpinnerOpen = false;
          this.showToaster('Network issue');
          setTimeout(() => {
            this.$router.push('/welcome');
          }, 2500);

        }
      } catch (error) {

        localStorage.setItem('device_token',0);
        localStorage.setItem('user_name','');
        localStorage.setItem('read_user_msg',0);
        localStorage.setItem('login_otp',0);

        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    },
    goToLoginPage(){
      this.$router.push('/login');
    },
  },
  mounted() {
    this.check_token_on_server();
  }
};
</script>