<template>

  <MenuBar />    

  <section class="section-top-bar">
    <div class="listing-top-bar">
      <div class="back-icon" @click="goToMenuPage('home')"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Profile
      </div>
    </div>
  </section>

  <nav class="menu-nav-bar show">
    
    <div class="profile-menu">
    <h5 @click="goToMenuPage('profileUpdate')"><i class="fa fa-user"></i>Account <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('memberList')"><i class="fa fa-plus-circle"></i>Add Profile <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('memberviewlist')"><i class="fa fa-users"></i>View History<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('creditPlan')"><i class="fa fa-trophy"></i>Membership<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('privacyPolicy')"><i class="fa fa-book"></i>Privacy Policy <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('helpCenter')"><i class="fa fa-globe"></i>Help Center<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="userLogout"><i class="fa fa-sign-out"></i> Logout<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>

    </div>
  </nav>


</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';

export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isProfileNavbarUserName:''
    }
  },
  methods: {
    goToMenuPage(menupage) {
      this.$router.push('/'+menupage);
    },
    userLogout(){
      localStorage.setItem('device_token','');
      localStorage.setItem('user_name','');
      localStorage.setItem('read_user_msg','')
      this.$router.push('/index');
    }
  },
  mounted() {
    const username = localStorage.getItem('user_name');
    if(username == '' || username == 'null' ){
      this.$router.push('/profileUpdate');
    }else{
      this.isProfileNavbarUserName = localStorage.getItem('user_name');
    }
    if(localStorage.getItem('device_token') == ''){
      this.userLogout();
    }
  }
};
</script>